<template>
  <moe-page class="category-detail" title="分类详情">
    <moe-form v-if="categoryData.id">
      <el-form-item label="上级分类规则">
        {{ parentIdComputed }}
      </el-form-item>
      <el-form-item label="分类级别">
        {{ levelOptions[categoryData.level] }}
      </el-form-item>
      <el-form-item label="分类名称">
        {{ categoryData.name }}
      </el-form-item>
      <el-form-item label="分类图片">
        <moe-image :src="categoryData.coverUrl" width="100px" height="100px"></moe-image>
      </el-form-item>
      <el-form-item label="分类排序">
        {{ categoryData.sort }}
      </el-form-item>
      <el-form-item label="分类状态">
        {{ categoryData.status === 1 ? '启用' : '禁用' }}
      </el-form-item>
      <el-form-item label="规格" v-if="categoryData.level === 3">
        <div class="specs-list" v-for="(item, index) in categoryData.specList" :key="index">
          <div class="specs-title mb-10">{{ item.name }}</div>
          <div class="df aic fww">
            <div class="specs-item" v-for="(specItem, specIndex) in item.valueList" :key="specIndex">{{ specItem.value }}</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="备注">
        {{ categoryData.remark }}
      </el-form-item>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'CategoryDetail',
  data() {
    return {
      categoryData: {},
      levelOptions: {
        1: '一级分类',
        2: '二级分类',
        3: '三级分类'
      }
    }
  },
  computed: {
    parentIdComputed() {
      if (!this.categoryData.fatherList) {
        return '根目录'
      }
      return this.categoryData.fatherList.map((item) => { return item.name }).join(' / ');
    }
  },
  methods: {
    /** 获取分类详情 */
    categoryVo() {
      this.$moe_api.GOODS_API.categoryVo({ id: this.$route.query.id }).then((data) => {
        if (data.code === 200) {
          this.categoryData = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.categoryVo();
  }
}
</script>

<style lang="scss" scoped>
.category-detail {
  .specs-list {
    .specs-title {

    }
    .specs-item {
      padding: 7px 10px 7px 10px;
      border: 1px solid $--color-info;
      border-radius: 4px;
      margin: 0 20px 20px 0;
      max-height: 36px;
      font-size: 14px;
      line-height: 1;
    }
  }
}
</style>